import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    login: {},
    try: 0,
    tryEmail: '',
    project: {},
    code: '',
    disclaimer: '',
    token: '',
    internal: '',
    lastSearch: [],
    lastSearchProjects: [],
    expired: new Date().setHours(new Date().getHours() + 2)
  },
  mutations: {
    setLogin (state, data) {
      state.login = data
      state.try = 0
      state.expired = new Date().setHours(new Date().getHours() + 2)
    },
    setLoginInternal (state) {
      state.internal = true
    },
    tryLogin (state, data) {
      if (state.tryEmail === data) {
        state.try += 1
      } else {
        state.try = 0
        state.tryEmail = data
      }
    },
    resetState (state) {
      Object.assign(state, {
        login: {},
        try: 0,
        tryEmail: '',
        project: {},
        code: '',
        disclaimer: '',
        token: '',
        internal: '',
        lastSearch: '',
        lastSearchProjects: [],
        expired: new Date().setHours(new Date().getHours() + 2)
      })
    },
    setProject (state, data) {
      state.project = data
    },
    setCode (state, data) {
      state.code = data
    },
    setDisclaimer (state, data) {
      state.disclaimer = data
    },
    setToken (state, data) {
      state.token = data
      state.expired = new Date().setHours(new Date().getHours() + 2)
    },
    setLastSearch (state, data) {
      state.lastSearch = data
    },
    setLastSearchProjects (state, data) {
      state.lastSearchProjects = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
