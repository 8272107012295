import * as Constants from '@/store/constants'
import * as Api from '@/api'

import store from '@/store'
import router from '@/router'

const LOGIN = '/auth/token/'
const RESET_PASSWORD = '/password_reset/'
const RESET_PASSWORD_CONFIRM = '/password_reset/confirm/'
const CHANGE_PASSWORD = '/change_password/'
const DISCLAIMER = '/disclaimers/'

const MEO_LOGIN = '/meoid/:code'
const MEO_CONNECT = '/meoid/connect/'

const AD_LOGIN = '/ad/token/'

export default {

  login (data) {
    return Api.http(false).post(LOGIN, data)
  },
  loginMeo (data) {
    return Api.http(false).get(MEO_LOGIN.replace(':code', data))
  },
  loginAd (data) {
    return Api.http(false).post(AD_LOGIN, data)
  },
  connectMeo (data) {
    return Api.http(false).post(MEO_CONNECT, data)
  },
  resetPassword (data) {
    return Api.http(false).post(RESET_PASSWORD, data)
  },
  resetPasswordConfirm (data) {
    return Api.http(false).post(RESET_PASSWORD_CONFIRM, data)
  },
  changePassword (data) {
    return Api.http(true).put(CHANGE_PASSWORD, data)
  },
  logout (internal = false) {
    let token = store.state.token
    store.commit('resetState')
    if (internal) {
      router.push('/')
    } else {
      router.push('/logout')
      window.location = Constants.MEOID_LOGOUT.replace('{id_token}', token)
    }
  },
  setDisclaimer () {
    return Api.http(true).put(DISCLAIMER, {})
  },
  getDisclaimerUrl () {
    return Api.http(false).get(DISCLAIMER)
  }

}
