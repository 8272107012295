// DEV
export const DEBUG = process.env.VUE_APP_DEBUG === true
export const PRODUCTION_TIP = process.env.VUE_APP_PRODUCTION_TIP ? process.env.VUE_APP_PRODUCTION_TIP : ''
export const ENV = process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : ''

export const API_BASEURL = process.env.VUE_APP_API_BASEURL ? process.env.VUE_APP_API_BASEURL : ''
export const BASEURL = process.env.VUE_APP_BASEURL ? process.env.VUE_APP_BASEURL : ''
export const MEOID_LOGIN = process.env.VUE_APP_MEOID_LOGIN ? process.env.VUE_APP_MEOID_LOGIN : ''
export const MEOID_LOGOUT = process.env.VUE_APP_MEOID_LOGOUT ? process.env.VUE_APP_MEOID_LOGOUT : ''

// PROD
// export const DEBUG = false
// export const PRODUCTION_TIP = true
// export const ENV = 'Production'
// export const API_BASEURL = 'https://projetos.clientesb2b.telecom.pt/api/v1'
// export const BASEURL = 'https://projetos.clientesb2b.telecom.pt'
// export const MEOID_LOGIN = 'https://id.services.telecom.pt/oic?state=CSRF-Me&client_id=_DfZFBMRjHWo-nyjAeXSOA&response_type=code&scope=openid&redirect_uri=https://projetos.clientesb2b.telecom.pt'
// export const MEOID_LOGOUT = 'https://id.services.telecom.pt/oic/signout?id_token_hint={id_token}&post_logout_redirect_uri=https://projetos.clientesb2b.telecom.pt'

export const VALIDATE_EMAIL = 'É necessário ser um email válido'
export const VALIDATE_MINLENGHT = 'Mínimo 9 caracteres'
export const VALIDATE_MATCH_PASSWORD = 'Password deve ser repetida'
export const VALIDATE_MAXLENGTH = 'Máximo 6 caracteres'
