import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import * as Constants from '@/store/constants'
import Auth from '@/api/services/Auth.js'
import Tracking from '@/api/services/Tracking.js'

import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueRouter)

Vue.use(VueLoading)

const homeRedirect = (to, from, next) => {
  Vue.prototype.$loading(true)
  if (to.query.code) {
    Auth.loginMeo(to.query.code).then((result) => {
      if (result.data.profile.length === 0) {
        next({ path: '/new-user', query: { 'data': result.data } })
      } else {
        store.commit('setLogin', result.data.login)
        store.commit('setToken', result.data.access.id_token)
        store.commit('setDisclaimer', result.data.profile[0].disclaimer)
        if ((store.state.disclaimer === null || store.state.disclaimer === '') && store.state.internal !== true) {
          Vue.prototype.$loading(false)
          next('/condicoes-de-utilizacao')
          return
        }
        next('/projects')
      }
      // eslint-disable-next-line handle-callback-err
    }).catch(() => {
    })
    return
  } else if (store.state.login.access && (new Date(store.state.expired) > new Date())) {
    if ((store.state.disclaimer === null || store.state.disclaimer === '') && store.state.internal !== true) {
      Vue.prototype.$loading(false)
      next('/condicoes-de-utilizacao')
      return
    }
    next('/projects')
    return
  }
  window.location = Constants.MEOID_LOGIN
}

// const parseHttpHeaders = (httpHeaders) => {
//   return httpHeaders.split('\n').map(x => x.split(/: */, 2)).filter(x => x[0]).reduce((ac, x) => { ac[x[0]] = x[1]; return ac }, {})
// }

const alticeHomeRedirect = (to, from, next) => {
  Vue.prototype.$loading(true)
  if (store.state.login.access && store.state.internal === true && (new Date(store.state.expired) > new Date())) {
    if (to.query.redirect) {
      next('/altice' + to.query.redirect)
    } else {
      next('/altice/projects')
    }
    Vue.prototype.$loading(false)
    return
  }
  Vue.prototype.$loading(false)
  next({
    path: '/altice/login',
    query: to.query
  })
}

const isLogged = (to, from, next) => {
  Tracking.sendTrack(to.fullPath)
  if (store.state.login.access && (new Date(store.state.expired) > new Date())) {
    next()
    return
  }
  next('/')
}

const isLoggedAltice = (to, from, next) => {
  if (store.state.login.access && store.state.internal === true && (new Date(store.state.expired) > new Date())) {
    next()
    return
  }
  next('/altice')
}

const DefaultTop = () => import(/* webpackChunkName: "default-top" */ '@/layouts/DefaultTop.vue')
const DefaultHeader = () => import(/* webpackChunkName: "default-header" */ '@/layouts/DefaultHeader.vue')
const DefaultFooter = () => import(/* webpackChunkName: "default-footer" */ '@/layouts/DefaultFooter.vue')
const Default = () => import(/* webpackChunkName: "default" */ '@/layouts/Default.vue')

const routes = [
  {
    path: '/',
    component: Default,
    props: true,
    children: [
      // Authentication
      {
        path: '',
        name: 'Home',
        beforeEnter: homeRedirect
      },
      {
        path: 'settings',
        name: 'Settings',
        components: {
          default: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
          header: DefaultTop,
          footer: DefaultFooter
        }
      },
      {
        path: 'new-user',
        name: 'NewUser',
        components: {
          default: () => import(/* webpackChunkName: "new-user" */ '../views/NewUser.vue'),
          header: DefaultHeader,
          footer: DefaultFooter
        }
      },
      {
        path: 'condicoes-de-utilizacao',
        name: 'Disclaimer',
        components: {
          default: () => import(/* webpackChunkName: "disclaimer" */ '../views/Disclaimer.vue'),
          header: DefaultHeader,
          footer: DefaultFooter
        }
      },
      {
        path: 'logout',
        name: 'Logout',
        components: {
          default: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
          header: DefaultHeader,
          footer: DefaultFooter
        }
      },
      // Web Pages
      {
        path: '/projects',
        name: 'Projects',
        components: {
          default: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
          header: DefaultTop,
          footer: DefaultFooter
        },
        beforeEnter: isLogged
      },
      {
        path: '/detail/:uuid',
        name: 'ProjectDetail',
        components: {
          default: () => import(/* webpackChunkName: "project-detail" */ '../views/ProjectDetail.vue'),
          header: DefaultTop,
          footer: DefaultFooter
        },
        props: true,
        beforeEnter: isLogged
      },
      // AD Access
      {
        path: '/altice',
        component: Default,
        props: true,
        children: [
          {
            path: '',
            name: 'AlticeHome',
            beforeEnter: alticeHomeRedirect
          },
          {
            path: 'sanity/:uuid',
            name: 'AlticeSanity',
            components: {
              default: () => import(/* webpackChunkName: "altice-sanity" */ '../views/Altice/Sanity.vue'),
              header: DefaultTop,
              footer: DefaultFooter
            },
            props: true,
            beforeEnter: isLoggedAltice
          },
          {
            path: 'login',
            name: 'AlticeLogin',
            components: {
              default: () => import(/* webpackChunkName: "altice-login" */ '../views/Altice/Login.vue'),
              header: DefaultHeader,
              footer: DefaultFooter
            }
          },
          {
            path: 'projects',
            name: 'AlticeProjects',
            components: {
              default: () => import(/* webpackChunkName: "altice-projects" */ '../views/Altice/Projects.vue'),
              header: DefaultTop,
              footer: DefaultFooter
            },
            beforeEnter: isLoggedAltice
          },
          {
            path: 'detail/:uuid',
            name: 'AlticeProjectDetail',
            components: {
              default: () => import(/* webpackChunkName: "altice-project-detail" */ '../views/Altice/ProjectDetail.vue'),
              header: DefaultTop,
              footer: DefaultFooter
            },
            props: true,
            beforeEnter: isLoggedAltice
          }
        ]
      },
      {
        path: '/*',
        name: 'NotFound',
        components: {
          default: () => import(/* webpackChunkName: "not-found" */ '@/layouts/NotFound.vue'),
          header: DefaultTop,
          footer: DefaultFooter
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
