<template>
  <div id="app">
    <cookie-law theme="altice" transitionName="slideFromTop" :message="message" buttonText="ACEITAR"></cookie-law>
    <router-view/>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  data () {
    return {
      message: 'Ao continuar a navegar está a concordar com a utilização de cookies neste site. Para não autorizar a sua utilização, por favor utilize as opções do seu browser e altere as definições de cookies de acordo com estas instruções. Para mais informações, consulte a nossa Política de Proteção de Dados Pessoais.'
    }
  },
  components: {
    CookieLaw
  }
}
</script>

<style lang="scss">
#app {
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.Cookie--altice {
  position: inherit !important;
  background: #ebebeb;
  color: #333333;
  font-family: Montserrat;
  font-size: 10px;
  text-align: left;
  padding: 2em;
    .Cookie__button {
      background: #ebebeb;
      padding: 10px 25px;
      letter-spacing: 0.12em;
      color: #000;
      border-radius: 0.5em;
      border: 0.1;
      border-color: #000;
      font-size: 10px;
      font-weight:700;
      &:hover {
        background-color: #0084D6;
        border-color:transparent;
        color: #fff;
      }
  }
}
</style>
