import * as Api from '@/api'

const TRACKING = '/tracking/'

export default {
  sendTrack (url) {
    return Api.http(true).post(TRACKING, {
      url: url
    })
  }
}
