import * as Constants from '@/store/constants'

import axios from 'axios'
import store from '@/store'
import router from '@/router'

import Auth from '@/api/services/Auth.js'

import { asyncLoading } from 'vuejs-loading-plugin'

// import Swal from 'sweetalert2'

export let cancelToken = null
export let cancel = () => {
  if (cancelToken) {
    // store.commit(Constants.MUTATION_SET_LOADING, false)
    cancelToken.cancel()
  }
}

export const http = (auth, base = Constants.API_BASEURL) => {
  let requester = axios.create({
    baseURL: base
  })

  if (auth) {
    requester.defaults.headers.Authorization = 'Bearer ' + store.state.login.access
  }

  // refresh token
  let failedQueue = []

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })
    failedQueue = []
  }
  // end refresh

  cancel()

  // Add a request interceptor
  requester.interceptors.request.use(config => {
    cancelToken = axios.CancelToken.source()
    config.cancelToken = cancelToken.token

    return config
  }, error => {
    return Promise.reject(error)
  })

  // Add a response interceptor
  requester.interceptors.response.use((response) => {
    // Do something with response data
    let result = Promise.resolve(response)
    return asyncLoading(result).then().catch()
  }, (error) => {
    switch (error.response.status) {
      case 401:
        let originalRequest = error.config
        originalRequest._retry = true
        let refreshToken = {
          'refresh': store.state.login.refresh
        }

        if (error.config.url === '/auth/token/refresh/' || !store.state.login.refresh) {
          store.commit('resetState')
          let result = Promise.reject(error)
          return asyncLoading(result).then().catch()
        }

        let resultRefresher = new Promise(function (resolve, reject) {
          requester.post('/auth/token/refresh/', refreshToken).then((response) => {
            store.commit('setLogin', response.data)
            requester.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.login.access
            originalRequest.headers['Authorization'] = 'Bearer ' + store.state.login.access
            processQueue(null, store.state.login.access)
            resolve(requester(originalRequest))
          }).catch((error) => {
            processQueue(error, null)
            router.push('/')
            let result = Promise.reject(error)
            return asyncLoading(result).then().catch()
          })
        })
        return resultRefresher
      default:
        let result = Promise.reject(error)
        return asyncLoading(result).then().catch()
    }
  })
  return requester
}

export default {
  Auth,
  http,
  cancel
}
